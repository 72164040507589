<template>
  <div class="container">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="item active">
          <p>
            {{ Slide1.description }}
          </p>
        </div>

        <div class="item">
          <p>
            {{ Slide2.description }}
          </p>
        </div>

        <div class="item">
          <p>
            {{ Slide3.description }}
          </p>
        </div>
        <div class="item">
          <p>
            {{ Slide4.description }}
          </p>
        </div>
      </div>

      <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <img class="white" src="../../assets/icons/arrow-left.png" alt="" />
      </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <img class="white" src="../../assets/icons/arrow-right.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slides",
  data() {
    return {
      Slide1: {
        description: "The Safari Experience 1",
      },
      Slide2: {
        description: "The Safari Experience 2",
      },
      Slide3: {
        description: "The Safari Experience 3",
      },
      Slide4: {
        description: "The Safari Experience 4",
      },
    };
  },
};
</script>

<style scoped>
.carousel-control {
  opacity: unset;
  background-image: none;
}
.glyphicon {
  margin-top: 60px;
  color: #32bad4;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 50px;
  box-shadow: none;
}
.item {
  line-height: 30px;
  text-align: center;
  margin: auto;
}
.item p {
  margin: auto;
  width: 75%;
  color: #006233;
  font-family: Courgette;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}
.container {
  /* padding: 150px 20px 50px 20px; */
  /* margin-top: 50px; */
  width: 70%;
  border-radius: 15px;
  color: #fff;
}
.carousel-inner {
  width: 100%;
  margin: auto;
  /* height: 200px; */
}

@media screen and (max-width: 720px) {
  .container {
    background-color: unset;
    width: 100%;
    color: #517a49;
  }
  .carousel-control {
    display: grid;
    place-items: center;
    opacity: unset;
    background-image: none;
  }
  .item p {
  font-size: 20px;
}
}
</style>