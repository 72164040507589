<template>
  <Header />
  <div class="home">
    <div class="layer">
      <Slides />
    </div>
  </div>
  <AdventureAwaits />
  <OurRooms />
  <Reviews/>
  <HoverSection/>
  <Footer />
</template>

<script>
import Footer from "../components/layouts/Footer";
import Slides from "../components/Homepage/Slides";
import AdventureAwaits from "../components/Homepage/AdventureAwaits";
import OurRooms from "../components/Homepage/OurRooms";
import Reviews from "../components/Homepage/Reviews";
import HoverSection from "../components/Homepage/HoverSection";
import Header from "../components/layouts/Header";
export default {
  name: "Home",
  components: {
    Header,
    Slides,
    AdventureAwaits,
    OurRooms,
    Reviews,
    HoverSection,
    Footer,
  },
  data() {
    return {
      images: [{}, {}, {}],
    };
  },
};
</script>
<style scoped>
.home {
  position: relative;
  background-image: url("../assets/homeimages/hero-backround.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* padding-top: 700px; */
  height: 700px;
  /* contain: content; */
}
.layer {
  position: absolute;
  top: 0px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
@media screen and (max-width:720px){
  .home {
  height: 100vh;
}
}
</style>