<template>
  <div id="nav">
    <!-- <Header /> -->
  </div>
  <router-view/>
</template>
<script>
  // import Header from "./components/layouts/Header"
export default {
  components:{
    // Header
  }
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    color: #517A49;
  /* font-family: "Barlow-SemiBold";
   */
   font-family: Courgette;
   max-width: 1500px;
   margin: auto;
}

/* #nav {
position: relative;
z-index: 10;
} */

#nav a {
  font-family: Barlow-Bold;
  font-weight: bold;
  color: #000;
}

#nav a.router-link-exact-active {
  color: #006233;
  text-decoration: underline;
}
h1{
  margin:unset;
}
</style>
