<template>
  <div id="navigation_bar">
    <div id="mySidepanel" class="sidepanel">
      <div class="mobile_menu">
        <div class="Close">
          <a href="javascript:void(0)" class="closebtn" @click="closeNav()"
            >×</a
          >
        </div>
        <div @click="closeNav()"><router-link to="/">Home</router-link></div>
        <div @click="closeNav()">
          <router-link to="/rooms">Our Rooms</router-link>
        </div>
        <div @click="closeNav()">
          <router-link to="/adventures">Adventures</router-link>
        </div>
        <div @click="closeNav()">
          <router-link to="/about">About Us</router-link>
        </div>
        <div @click="closeNav(), scrollTo('contact')">
          <router-link to="/about">Contact Us</router-link>
        </div>
        <div class="book_btn" @click="closeNav(), scrollTo('contact')">
          <a>Book Now</a>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-inverse">
      <div class="container-fluid">
        <div class="navbar-header">
          <div>
            <button @click="openNav()" type="button" class="navbar-toggle">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="brandName" @click="closeNav()">
            <router-link to="/"><img :src="logo" alt="" /></router-link>
          </div>
        </div>
        <div class="collapse navbar-collapse">
          <div class="Navigations">
            <ul class="nav navbar-nav">
              <li @click="scrollTo('navigation_bar')">
                <router-link to="/">Home</router-link>
              </li>
              <li @click="scrollTo('navigation_bar')">
                <router-link to="/rooms">Our Rooms</router-link>
              </li>
              <li @click="scrollTo('navigation_bar')">
                <router-link to="/adventures">Adventures</router-link>
              </li>
              <li @click="scrollTo('navigation_bar')">
                <router-link to="/about">About Us</router-link>
              </li>
              <li @click="scrollTo('contact')">
                <router-link to="/about" style="color: #000"
                  >Contact Us</router-link
                >
              </li>
              <li class="book_btn">
                <a>Book Now</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/icons/greenhousLogo.webp"),
    };
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openNav() {
      document.getElementById("mySidepanel").style.height = "100%";
    },
    closeNav() {
      document.getElementById("mySidepanel").style.height = "0";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
.navbar-nav li a.router-link-exact-active {
  color: #006233;
}
.Navigations {
  width: 100%;
  display: grid;
  place-items: center;
}
.navbar-toggle {
  margin: unset;
  position: absolute;
  right: 48%;
  top: 120px;
}
.navbar-inverse .navbar-toggle .icon-bar {
  color: black;
  border-color: none;
  background-color: black;
}
.navbar-inverse .navbar-toggle:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.navbar-header {
  margin: unset;
}
.navbar-inverse {
  background-color: #ffffff;
  border: none;
  height: 150px;
}
nav .container-fluid {
  display: block;
  width: 100%;
  margin: unset;
  padding: unset;
}
.navbar-header {
  width: 100%;
}

.collapse {
  padding: unset;
}
.navbar-nav li a {
  font-size: 12px;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #000;
  text-decoration: none;
}
.brandName img {
  max-width: 200px;
}
.brandName {
  padding: 20px;
  display: grid;
  place-items: center;
  /* background-color: #000; */
}
.navbar-nav li a:hover {
  /* font-size: 12px; */
  color: #006233;
  text-decoration: none;
}
.navbar-nav li a:active {
  color: #000;
}

img:hover {
  cursor: pointer;
}

.sidepanel {
  width: 100%;
  position: fixed;
  z-index: 1000;
  height: 0;
  top: 0;
  right: 0;
  background-color: #006233;
  overflow-x: hidden;
  transition: 0.5s;
  display: grid;
  place-items: center;
}

.sidepanel a {
  text-align: center;
  font-weight: 500;
  padding: 8px;
  text-decoration: none;
  font-size: 26px;
  color: #fff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

.sidepanel .SocialIcons a {
  padding: unset;
  display: flex;
  float: right;
}
.sidepanel .SocialIcons {
  display: flex;
}

.Close a {
  font-weight: unset;
  font-size: 45px;
  padding-bottom: 30px;
  padding-left: 20px;
  transition: 100ms;
  transition-timing-function: ease-in-out;
}
.Close a:hover {
  color: #1f1e1e;
  font-size: 43px;
}
.sidepanel .closebtn {
  padding: 0px;
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 5px;
}
#mySidepanel .book_btn {
  background-color: #fff;
  border-radius: 15px;
}

#mySidepanel .book_btn a {
  color: #006233;
}
.nav .book_btn {
  background-color: #006233;
  border-radius: 15px;
  height: 60px;
}
.nav .book_btn a {
  color: #fff;
  cursor: pointer;
}
.nav .book_btn a:hover {
  color: #fff;
}
@media only screen and (max-width: 380px) {
  .navbar-toggle {
    right: 43%;
  }
}
</style>