<template>
  <div class="footer">
    <div class="locatioDetails">
      Sombetini, Arusha, <br />
      Tanzania, East Africa <br />
      info@greenliving.org <br />
      Tel: +255 764 181 825 / +447871054415 <br />
      PO Box 14888 Arusha
    </div>
    <div class="socialMedia">
      <a href="" target="_blank">
        <img src="../../assets/icons/instagram-white.png" alt="" />
      </a>
      <a href="" target="_blank">
        <img src="../../assets/icons/facebook-white.png" alt="" />
      </a>
      <a href="" target="_blank">
        <img src="../../assets/icons/tripadvisor-white.png" alt="" />
      </a>
      <a href="" target="_blank">
        <img src="../../assets/icons/Hotel.png" alt="" />
      </a>
    </div>
    <div class="footaNav">
      <div @click="scrollTo('navigation_bar')" class="viewMorebtn">
          <router-link to="/rooms"><button>Book Now</button></router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      copyright:
        "All Rights Reserved, The Greenhouse Hostel, " + this.getYear(),
    };
  },
  methods: {
    getYear() {
      var date = new Date();
      var year = date.getFullYear();
      return year;
    },
  scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #333;
  color: #fff;
  padding: 10px 50px 10px 50px;
}
button {
  height: 50px;
  width: 160.52px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  background-color: #006233;
}
.locatioDetails {
  font-size: 20px;
  line-height: 24px;
  margin: 20px auto 20px 0px;
}
.socialMedia img {
  height: 32px;
}
.socialMedia {
  display: flex;
  justify-content: center;
  margin: auto;
}
.footaNav {
  display: grid;
  place-items: center;
}
.socialMedia a {
  margin-right: 30px;
  display: grid;
  place-items: center;
}
@media screen and (max-width: 720px) {
  .footer {
    display: grid;
    place-items: center;
    text-align: center;
  }
  .socialMedia {
    margin: 20px;
  padding-left: 30px;

  }
  .footaNav {
  margin-bottom: 50px;
}
}
</style>