<template>
  <div class="notfound">
    <div>
      <h1>404</h1>
      <p>Page Not Found</p>
      <p>
        <router-link to="/">Back to home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.notfound {
  height: 100vh;
  display: grid;
  place-items: center;
}

h1 {
  color: var(--border);
  font-size: 100px;
  font-weight: 700px;
}
</style>
