<template>
  <div class="review">
    <h1>Reviews</h1>
    <p>What our friends say about us.</p>
    <div class="testimonials">
      <ReviewsDetails
        class="thereviewSection"
        v-for="review in reviews"
        :key="review.id"
        :title="review.title"
        :desc="review.desc"
        :icon="review.icon"
      />
    </div>
    <div @click="scrollTo('contact')" class="viewMorebtn">
      <router-link to="/about" style="color: #000">
        <button>Contact Us</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import ReviewsDetails from "./ReviewsDetails";
export default {
  components: {
    ReviewsDetails,
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  data() {
    return {
      reviews: [
        {
          title: "Angelique W.",
          desc: "Ben and his family at greenhouse are the most accommodating people that become family! He went above and beyond to ensure we had all our trips organised and had everything that we need.",
          icon: require("../../assets/homeimages/review1.png"),
        },
        {
          title: "Joe Bruke",
          desc: 'I would recommend this hostel to anyone. The owner Ben is very accommodating. He and his staff really make you feel at home. Everything we asked for was "no problem."',
          icon: require("../../assets/homeimages/review2.png"),
        },
        {
          title: "Jesse K",
          desc: "I stayed at the Greenhouse Hostel for 3 days and had a great time! The rooms were clean, shower was hot, and we had a filling breakfast every day. ",
          icon: require("../../assets/homeimages/review3.png"),
        },
        {
          title: "Klaus",
          desc: "Comfy house, with place to chill, easy + tasty breakfast, clean bathrooms... Helpful staff, pub near by. And above all a well organized and wicked safari. I will be back.",
          icon: require("../../assets/homeimages/review1.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.review {
  padding: 80px 0px 80px 0px;
  color: #fff;
  width: 100%;
  background-color: #006233;
}
.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.thereviewSection {
  margin: 20px;
}
.review h1 {
  font-family: Courgette;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}
.review p {
  padding: 20px;
  font-family: Raleway;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
}
.viewMorebtn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
button {
  color: #fff;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  background-color: transparent;
  box-sizing: border-box;
  height: 72px;
  width: 181px;
  border-radius: 8px;
  border: 2px solid #fff;
}
</style>