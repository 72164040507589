<template>
  <div class="explore">
    <div class="Containgdetails">
      <p>{{title}}</p>
    </div>
    <p class="exploreTxt">Explore</p>
    <img :src="image" alt="" />
  </div>
</template>

<script>
export default {
    props:{
        title: String,
        image: String,

    }
};
</script>

<style scoped>
.explore {
  position: relative;
  /* background-color: thistle; */
  max-width: 400px;
  margin: 10px;
}
.Containgdetails {
  position: absolute;
  /* background-color: rgba(240, 230, 140,0.5); */
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.Containgdetails p {
  color: #fff;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
}
p.exploreTxt {
    color: #fff;
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
}
img{
    width: 100%;
}
</style>