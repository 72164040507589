<template>
  <div class="revie_details">
    <img :src="icon" />
    <div class="reviews">
      <h2>{{ title }}</h2>
      <p>
        Ben and his family at greenhouse are the most accommodating people that
        become family! He went above and beyond to ensure we had all our trips
        organised and had everything that we need.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    icon: String,
  },
};
</script>

<style scoped>
.revie_details {
  display: flex;
}
.revie_details img{
    margin: 10px;
    border-radius: 50px;
    border: 5px solid #fff;
  height: 72px;
  width: 72px;
}
.revie_details h2 {
  /* max-width: 400px; */
  color: #fff;
  font-size: 28px;
  line-height: 35px;
}
.revie_details p {
  max-width: 402px;
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
}
@media screen and (max-width:720px){
  .revie_details {
    display: grid;
  place-items: center;
}
.revie_details h2 {
  text-align: center;
}
.revie_details p {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
}
</style>