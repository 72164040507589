<template>
  <div class="homeOurRooms">
    <div class="titleSection">
      <h1>Our Rooms</h1>
      <p>Stay with us as you embark on your adventure.</p>
      <div class="iconsection">
        <img src="../../assets/icons/wifi.png" alt="">
        <img src="../../assets/icons/airport-shuttle.png" alt="">
        <img src="../../assets/icons/laundry_service.png" alt="">
        <img src="../../assets/icons/restaurant.png" alt="">
        <img src="../../assets/icons/directions_bike.png" alt="">
      </div>
      <div class="explore">
        <Explore
          v-for="detail in details"
          :key="detail.id"
          :title="detail.title"
          :image="detail.image"
        />
      </div>
      <div @click="scrollTo('navigation_bar')" class="viewMorebtn">
        <router-link to="/rooms"><button>View Our Rooms</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Explore from "../Explore.vue";
export default {
  components: { Explore },
  data() {
    return {
      details: [
        {
          title: "Individuals",
          image: require("../../assets/homeimages/individual.png"),
        },
        {
          title: "Couples",
          image: require("../../assets/homeimages/couple.png"),
        },
        {
          title: "Groups",
          image: require("../../assets/homeimages/groups.png"),
        },
      ],
    };
  },
  methods: {
     scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
.explore {
  margin-top: 20px;
  display: flex;
  /* flex-wrap:wrap; */
  justify-content: center;
}
.iconsection{
  flex-wrap:wrap;
  display: flex;
  justify-content: center;
  padding: 20px auto 20px auto;
}
.iconsection img{
  margin: 20px;
}
a{
  color: #006233;
}
.homeOurRooms {
  padding: 80px 0px 80px 0px;
  /* height: 664px; */
  /* background-color: #006233; */
}
.titleSection {
  color: #000;
}
.viewMorebtn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.titleSection button {
  background-color: transparent;
  font-family: Courgette;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  box-sizing: border-box;
  height: 72px;
  width: 255.37px;
  border-radius: 8px;
  border: 2px solid #000;
}
.titleSection h1 {
  font-family: Courgette;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}
.titleSection p {
  font-family: Raleway;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
}
@media screen and (max-width:720px){
  .explore {
  display: block;
}
}
</style>