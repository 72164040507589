<template>
  <div class="hoverDetails">
    <div>
      <p>Book A Room</p>
      <p>Book A Safari</p>
      <p>Book Mount Climbing</p>
      <p>Book A Zanzibar Trip</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.hoverDetails {
  height: 572px;
  width: 100%;
  background-image: url("../../assets/homeimages/BookARoom.png");
  background-position: right;
  background-repeat: no-repeat;
  display: grid;
}
.hoverDetails div {
    margin-top: 100px;
    margin-left: 50px;
    height: 400px;
  max-width: 400px;
  display: grid;
}
.hoverDetails p {
    cursor: pointer;
  color: #000;
  font-size: 40px;
  line-height: 50px;
}
.hoverDetails p:hover {
  color: #006233;
}
</style>